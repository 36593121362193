export const FILTERS = [{ key: 'search', title: 'ФИО, телефон или E-mail' }];

export const COLUMNS = [
  { key: 'surname', title: 'Фамилия' },
  { key: 'name', title: 'Имя' },
  { key: 'email', title: 'E-mail' },
  { key: 'phone', title: 'Телефон' },
  { key: 'role', title: 'Роль' },
  { key: 'devices', title: '' },
  { key: 'resetPass', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const VEHICLE_FILTERS = {
  vin: 'VIN',
  plateNumber: 'Гос. номер',
};

export const VEHICLE_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'brandModel', title: 'Бренд и модель' },
];

export const AG_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'name', title: 'Название группы доступа' },
  { key: 'rights', title: 'Тип доступа' },
];
