import { createBaseApi, configRequest } from './baseApi';
const baseApi = createBaseApi();
export const settingsService = { getSettings, updateSettings };

function getSettings() {
  return baseApi.get(`/settings`, configRequest);
}

function updateSettings(body) {
  return baseApi.put(`/settings`, body, configRequest);
}
