import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Button, ButtonTypes, Input, InputTypes, ERRORS } from '@bs/techconnect-ui';
import { ReactComponent as Remove } from 'icons/cross_s.svg';
import { ReactComponent as Delete } from 'icons/delete_s.svg';
import { ReactComponent as Add } from 'icons/plus_xs.svg';

import styles from '../FirmwarePage.module.css';

export const CompatibilityRow = ({ index, removeRow, control, register }) => {
  const [newValue, setNewValue] = useState('');
  const { fields, append, remove } = useFieldArray({ control, name: `compatibility.${index}.value` });
  const addNewValue = () => {
    append({ value: newValue });
    setNewValue('');
  };

  return (
    <div className={styles['rule-row']}>
      <Button variant={ButtonTypes.R} onClick={() => removeRow(index)}>
        <Delete />
      </Button>
      <Input
        variant={InputTypes.L}
        className={styles['rule-key']}
        placeholder="Ключ"
        register={register(`compatibility.${index}.key`, { required: ERRORS.REQUIRED('Ключ') })}
      />
      <div className={styles['rule-border']} />
      <div className={styles['rule-values']}>
        <div className={styles['rule-add-wrapper']}>
          <Input
            variant={InputTypes.L}
            className={styles['rule-add']}
            placeholder="Значение"
            value={newValue}
            onChange={({ target }) => setNewValue(target.value)}
            onEnter={addNewValue}
          />
          <Add onClick={addNewValue} />
        </div>
        {fields.map(({ value }, i) => (
          <div key={'row-value-' + index + value + i} className={styles['rule-value']}>
            {value}
            <Remove onClick={() => remove(i)} />
          </div>
        ))}
      </div>
    </div>
  );
};

CompatibilityRow.defaultProps = {
  index: 0,
  removeRow: () => {},
  register: () => {},
};

CompatibilityRow.propTypes = {
  index: PropTypes.number,
  removeRow: PropTypes.func,
  register: PropTypes.func,
  control: PropTypes.object,
};
