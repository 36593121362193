import { Routes, Route } from 'react-router-dom';

import { AbilityWrapper, Error, Paper } from '@bs/techconnect-ui';
import { abilityPath, useUser } from 'helpers';
import Users from 'components/Users/Users';
import CommandsHistory from 'components/CommandsHistory/CommandsHistory';
import Firmwares from 'components/Firmwares/Firmwares';
import FirmwarePage from 'components/FirmwarePage/FirmwarePage';
import Tasks from 'components/Tasks/Tasks';
import TaskPage from 'components/TaskPage/TaskPage';
import DevicesFailed from 'components/DevicesFailed/DevicesFailed';
import UserPage from 'components/UserPage/UserPage';
import Settings from 'components/Settings/Settings';
import ApiKeyPage from 'components/Settings/ApiKeyPage/ApiKeyPage';

import styles from './Body.module.css';

const Body = () => {
  const { user } = useUser();

  return (
    <Paper className={styles['wrapper']}>
      <Routes>
        <Route element={<AbilityWrapper nav_menu={abilityPath} user={user} />}>
          <Route path="/" element={<Firmwares />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserPage />} />
          <Route path="/firmwares" element={<Firmwares />} />
          <Route path="/firmwares/:firmwareId" element={<FirmwarePage />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/tasks/:taskId" element={<TaskPage />} />
          <Route path="/history" element={<CommandsHistory />} />
          <Route path="/devicesFailed" element={<DevicesFailed />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/:apikeyId" element={<ApiKeyPage />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </Paper>
  );
};

export default Body;
