import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiKeysService } from 'services';
import { useSuccess } from 'helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Button, ButtonTypes, Checkbox, SelectDate, TextArea, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from '../ApiKeyPage/ApiKeyPage.module.css';

const ApiKeyPage = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { isDirty },
  } = useForm({ mode: 'onTouched' });
  const { apikeyId } = useParams();
  const { setSuccess } = useSuccess();

  const [date, setDate] = useState();
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const isCreate = apikeyId === 'new';

  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      apiKeysService
        .getApiKey(apikeyId)
        .then(({ status, data }) => {
          if (status === 200 && data) {
            reset(data);
            setDate(data?.expiresAt);
            setSelected(data?.isBlocked);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isCreate, reset, apikeyId]);

  const submit = form => {
    setLoading(true);
    const { description } = form;
    const body = { description, isBlocked: selected, expiresAt: date };
    const method = isCreate ? apiKeysService.createApiKey : apiKeysService.updateApiKey;
    method(body, apikeyId).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200 && data) {
        setSuccess('Изменения сохранены');
      }
    });
  };

  const exit = () => (isDirty ? setExitModal(true) : navigate('/settings'));

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.SR} onClick={exit}>
          <Back />
        </Button>
        <h3>{isCreate ? 'Создание' : 'Редактирование'} API KEY</h3>

        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>
      </header>
      {!isCreate && (
        <div className={styles['api_key-wrapper']}>
          <span className={styles['apikeyId']}>api-key</span>
          <span>{apikeyId}</span>
        </div>
      )}
      <TextArea label={'Описание'} placeholder=" " register={register('description')} />
      <SelectDate
        className={styles['date']}
        date={date}
        setDate={setDate}
        minDate={new Date()}
        label={'Дата истечения срока действия'}
      />
      <Checkbox
        checked={selected}
        onChange={() => setSelected(!selected)}
        className={styles['checkbox']}
        label="Заблокирован"
      />

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate('/settings')} />
    </div>
  );
};

export default ApiKeyPage;
