import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { authService } from 'services';
import { Button, ERRORS, Input } from '@bs/techconnect-ui';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './Login.module.css';

export const Login = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  const signIn = form => {
    authService.signIn(form).then(({ status, data = {} }) => {
      if (status === 200) {
        localStorage.setItem('user', JSON.stringify(data));
        //На других вкладках это будет вызывать два вызова события "storage"
        //Проблемой быть не должно
        window.dispatchEvent(new Event('storage'));
        navigate('/firmwares');
      }
    });
  };

  return (
    <div className={styles['wrapper']}>
      <Logo className={styles['logo']} />
      <header>
        techconnect
        <br />
        <b>FOTA Portal</b>
      </header>
      <span className={styles['title']}>Авторизация</span>

      <form className={styles['form']} onSubmit={handleSubmit(signIn)}>
        <Input
          placeholder="E-mail"
          register={register('email', { required: ERRORS.REQUIRED('E-mail') })}
          error={errors.email}
          disabled={isSubmitting}
        />

        <Input
          type="password"
          placeholder="Пароль"
          register={register('password', { required: ERRORS.REQUIRED('Пароль') })}
          error={errors.password}
          disabled={isSubmitting}
        />

        <Button type="submit" disabled={!isValid || isSubmitting}>
          Войти
        </Button>
      </form>
    </div>
  );
};
