import { createBaseApi, configRequest, prepareQuery } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const commandService = { getCommands, exportCommands };

function getCommands(params = {}) {
  return baseApi.get(`/commands?${prepareQuery(trimObject(params))}`, configRequest);
}

function exportCommands(params = {}) {
  return baseApi.get(`/commands/export?${prepareQuery(trimObject(params))}`, configRequest);
}
