import { createBaseApi, configRequest, prepareQuery } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const userService = {
  getUser,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserDevices,
  addUserDevice,
  deleteUserDevice,
};

function getUser(id) {
  return baseApi.get(`/users/${id}`, configRequest);
}

function getUsers(params = {}) {
  return baseApi.get(`/users?${prepareQuery(trimObject(params))}`, configRequest);
}

function createUser(body) {
  return baseApi.post('/users', trimObject(body), configRequest);
}

function updateUser(body, id) {
  return baseApi.put(`/users/${id}`, trimObject(body), configRequest);
}

function deleteUser(id) {
  return baseApi.delete(`/users/${id}`, configRequest);
}

function getUserDevices(id, params = {}) {
  return baseApi.get(`/users/${id}/devices?${prepareQuery(trimObject(params))}`, configRequest);
}

function addUserDevice(body, id) {
  return baseApi.post(`/users/${id}/devices`, trimObject(body), configRequest);
}

function deleteUserDevice(data, id) {
  return baseApi.delete(`/users/${id}/devices`, { data }, configRequest);
}
