import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { deviceService } from 'services';
import { COLUMNS, FILTERS } from './helpers';
import { Button, ButtonTypes, ListFilters, Popper, Table, Shorter, pageSize, parseDate } from '@bs/techconnect-ui';
import { ReactComponent as ListCommand } from 'icons/list.svg';

import styles from './DevicesFailed.module.css';

const DevicesFailed = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const fetchDevicesFailed = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`devicesfailedFilters`) || '{}') || {};
    setLoading(true);
    deviceService
      .getDevicesFailed({ page, pageSize, ...filterData })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchDevicesFailed();
  }, [page, filters, fetchDevicesFailed]);

  const navHandler = esn => {
    localStorage.setItem(`historyFilters`, JSON.stringify({ esn }));
    navigate('/history');
  };

  const handler = row => ({
    ...row,
    lastFailed: parseDate(row?.lastFailedCommand?.createdAt),
    firmwareName: row?.lastFailedCommand?.firmware?.name,
    taskName: <Shorter length={130}>{row?.lastFailedCommand?.task?.name}</Shorter>,
    failReason: <Shorter length={130}>{row?.lastFailedCommand?.failReason}</Shorter>,
    history: (
      <Popper popup={'Перейти к истории команд'}>
        <Button variant={ButtonTypes.TL} onClick={() => navHandler(row?.esn)}>
          <ListCommand />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Проблемные устройства"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        updateList={fetchDevicesFailed}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="esn"
        handler={handler}
      />
    </>
  );
};

export default DevicesFailed;
