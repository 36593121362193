import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import { firmwareService } from 'services';
import { decodeForm, encodeForm } from './helper';
import { useSuccess } from 'helpers';
import { FIRMWARE_STATUSES, fileNamePlaceholder } from 'components/Firmwares/helpers';
import { CompatibilityRow } from './CompatibilityRow/CompatibilityRow';
import { TargetVersionRow } from './TargetVersionRow/TargetVersionRow';
import { AlertModal } from 'components/AlertModal/AlertModal';
import {
  Button,
  ButtonTypes,
  Input,
  InputTypes,
  Switch,
  Upload,
  updateFormErrors,
  ERRORS,
  FIELD_VALIDATION,
} from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';
import { ReactComponent as Add } from 'icons/plus_xs.svg';

import styles from './FirmwarePage.module.css';

const FirmwarePage = () => {
  const { firmwareId } = useParams();
  const isCreate = firmwareId === 'new';
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
  } = useForm({ mode: 'onTouched', defaultValues: { name: '', status: FIRMWARE_STATUSES.inactive } });
  const {
    fields: compatibility = [],
    remove: removeСompatibility,
    append: appendСompatibility,
  } = useFieldArray({ control, name: 'compatibility' });
  const {
    fields: targetVersion = [],
    remove: removeTargetVersion,
    append: appendTargetVersion,
  } = useFieldArray({ control, name: 'targetVersion' });

  const { getFirmware, createFirmware, updateFirmware, uploadFirmware } = firmwareService;
  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      getFirmware(firmwareId)
        .then(({ status, data }) => {
          if (status === 200 && data) {
            reset(decodeForm(data));
            setFile({ name: data.filename });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [firmwareId, getFirmware, isCreate, reset]);

  const submit = form => {
    setLoading(true);
    const method = isCreate ? createFirmware : updateFirmware;
    method(encodeForm(form), firmwareId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${isCreate ? 'Прошивка создана' : 'Изменения сохранены'} `);
          navigate('/firmwares');
          if (file.size) {
            uploadFirmware(data?.id, { file, filename: file.name }).then(({ status }) => {
              if (status === 200) setSuccess('Файл прошивки сохранен');
            });
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const exit = () => (isDirty ? setExitModal(true) : navigate('/firmwares'));

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.SR} onClick={exit}>
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} прошивки</h3>

        <Button className={styles['submit-btn']} disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>
      </header>

      <div className={styles['fields']}>
        <Input
          placeholder="Название"
          className={styles['field']}
          register={register('name', {
            required: ERRORS.REQUIRED('Название'),
            validate: v => FIELD_VALIDATION.DURATION(v, 1, 500),
          })}
          error={errors.name}
          disabled={loading}
        />

        <Upload
          className={styles['field']}
          title={file?.name || fileNamePlaceholder}
          onChange={({ target }) => setFile(target?.files[0])}
          isSelect={file?.name}
          clear={() => setFile({})}
        />

        <Controller
          control={control}
          name="status"
          render={({ field: { onChange, value } }) => (
            <Switch
              className={styles['status']}
              label={`Прошивка ${value === FIRMWARE_STATUSES.active ? 'активна' : 'неактивна'}`}
              checked={value === FIRMWARE_STATUSES.active}
              onChange={e => {
                onChange(e.target.checked ? FIRMWARE_STATUSES.active : FIRMWARE_STATUSES.inactive);
              }}
            />
          )}
        />
      </div>

      <div className={styles['compatibility']}>
        <header>Правила совместимости</header>
        {compatibility.map(({ id, value }, index) => (
          <CompatibilityRow
            key={id}
            value={value}
            index={index}
            control={control}
            removeRow={removeСompatibility}
            register={register}
          />
        ))}
        <div className={styles['rule-row']}>
          <Button variant={ButtonTypes.R} onClick={() => appendСompatibility({ key: '', value: [] })}>
            <Add />
          </Button>
          <Input variant={InputTypes.L} className={[styles['rule-key'], styles['rule-key-empty']].join(' ')} />
          <div className={styles['rule-border']} />
        </div>
      </div>

      <div className={styles['target-version']}>
        <header>Ожидаемые версии</header>
        {targetVersion.map(({ key, value }, index) => (
          <TargetVersionRow
            key={key + index}
            value={value}
            index={index}
            removeRow={removeTargetVersion}
            register={register}
          />
        ))}
        <TargetVersionRow control={control} addRow={appendTargetVersion} />
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate('/firmwares')} />
    </div>
  );
};

FirmwarePage.defaultProps = {
  open: false,
  setOpen: () => {},
  firmware: {},
  updateList: () => {},
};

FirmwarePage.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  firmware: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  updateList: PropTypes.func,
};

export default FirmwarePage;
