import { FilterTypes, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { ROLES } from 'helpers';
import { firmwareService, taskService, userService } from 'services';

export const usersEmailMapper = ({ data, status }) => {
  if (status === 200 && Array.isArray(data?.items)) {
    return data.items.map(({ _id, email }) => ({ id: _id, email }));
  }
  return [];
};

const tasksMapper = ({ data, status }) => {
  if (status === 200 && Array.isArray(data?.items)) {
    return data.items.filter(({ name }) => name !== '').map(({ _id, name }) => ({ id: _id, name }));
  }
  return [];
};

export const firmwareMapper = ({ data, status }) => {
  if (status === 200 && Array.isArray(data?.items)) {
    return data.items.map(({ _id, name }) => ({ id: _id, name }));
  }
  return [];
};

export const firmwareFilter = {
  key: 'firmwareId',
  title: 'Прошивка',
  type: FilterTypes.s,
  source: firmwareService.getFirmwares,
  mapper: firmwareMapper,
  sourceId: firmwareService.getFirmware,
  titleKey: 'name',
};

export const userFilter = {
  key: 'userId',
  title: 'Пользователь',
  type: FilterTypes.s,
  source: userService.getUsers,
  mapper: usersEmailMapper,
  sourceId: userService.getUser,
  titleKey: 'email',
  hasSearch: true,
};

export const STATE_ENUM = {
  requested: 'Requested',
  processing: 'Processing',
  success: 'Success',
  fail: 'Fail',
  downloaded: 'Downloaded',
};

const validateDate = (from, to) => {
  if (!from?.getTime || !to?.getTime) return true;
  return from.getTime() > to.getTime() ? 'Дата "от" не может быть меньше даты "до"' : true;
};
// TODO: Продумать реализацию подгрузки всего списка полученных данных в компонентах Select, Multiselect
export const FILTERS = [
  { key: 'from', title: 'От', type: FilterTypes.d, validate: (v, values) => validateDate(v, values.to) },
  { key: 'to', title: 'До', type: FilterTypes.d, validate: (v, values) => validateDate(values.from, v) },
  {
    key: 'taskId',
    title: 'Задание',
    type: FilterTypes.s,
    source: taskService.getTasks,
    mapper: tasksMapper,
    sourceId: taskService.getTask,
    titleKey: 'name',
  },
  firmwareFilter,
  userFilter,

  { key: 'esn', title: 'ESN' },
  {
    key: 'state',
    title: 'Статус',
    type: FilterTypes.s,
    list: Object.keys(STATE_ENUM).map(id => ({ id, title: STATE_ENUM[id] })),
    validate: v => FIELD_VALIDATION.DURATION(v, 3, 130) || '',
  },
];

export const abilityFilter = (role, filters = []) => {
  if (role === ROLES.A) return filters;
  return filters.filter(({ key }) => key !== 'userId');
};

export const COLUMNS = [
  { key: 'createdAt', title: 'Создание' },
  { key: 'completedAt', title: 'Завершение' },
  { key: 'task', title: 'Задание' },
  { key: 'firmware', title: 'Прошивка' },
  { key: 'email', title: 'Пользователь' },
  { key: 'esn', title: 'ESN' },
  { key: 'state', title: 'Статус' },
  { key: 'isTargerVersionIncorrect', title: 'Ожидаемые версии' },
  { key: 'expendArrow', title: '' },
];
