import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const PASSWORD_FORM = password => [
  {
    key: 'password',
    type: 'password',
    title: 'Новый пароль',
    options: {
      required: ERRORS.REQUIRED('Новый пароль'),
      pattern: FIELD_VALIDATION.PASSWORD,
    },
  },
  {
    key: 'repeatPassword',
    type: 'password',
    title: 'Подтверждение пароля',
    options: {
      required: ERRORS.REQUIRED('Подтверждение пароля'),
      validate: v => v === password || ERRORS.PASSWORD_REPEAT,
    },
  },
];
