import { FIRMWARE_STATUSES } from 'components/Firmwares/helpers';

const addZero = v => (v.toString().length === 1 ? '0' + v : v);
const convertTimeToUTC = time => {
  const timeArr = time.split(':');
  const date = new Date();
  date.setHours(timeArr[0]);
  date.setMinutes(timeArr[1]);
  return [addZero(date.getUTCHours()), addZero(date.getMinutes())].join(':');
};
const convertTimeToLocal = time => {
  const timeArr = time.split(':');
  const date = new Date();
  date.setHours(timeArr[0]);
  date.setMinutes(timeArr[1]);
  console.log(date.getTimezoneOffset());
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return [addZero(utcDate.getHours()), addZero(utcDate.getMinutes())].join(':');
};

export const encodeForm = (form, firmwareCompatibility) => {
  const { firmwareId, status = FIRMWARE_STATUSES.inactive, compatibility, name, devices, timeFrom, timeTo } = form;

  const body = {
    name,
    firmwareId,
    status,
    devices: devices.map(d => d.value),
    timeFrom: convertTimeToUTC(timeFrom),
    timeTo: convertTimeToUTC(timeTo),
  };

  body.compatibility = { ...encodeFirmware(firmwareCompatibility) };
  if (compatibility?.length) {
    compatibility.forEach(({ key, value }) => (body.compatibility[key] = value.map(v => v.value)));
  }
  return body;
};
const encodeFirmware = compatibility => {
  const result = {};
  Object.keys(compatibility).forEach(key => {
    const values = [];
    Object.keys(compatibility[key]).forEach(value => {
      if (compatibility[key][value]) values.push(value);
    });
    result[key] = values;
  });
  return result;
};

export const decodeForm = (task, firmware) => {
  const firmwareCompatibility = firmware?.compatibility || {};
  if (!task) {
    return { newFirmwareCompatibility: decodeFirmware(firmwareCompatibility) };
  }
  const { name, _id, status, compatibility = {}, devices = [], timeFrom, timeTo } = task || {};

  const form = {
    name,
    _id,
    firmwareId: firmware?._id || task?.firmware?._id,
    status,
    timeFrom: convertTimeToLocal(timeFrom),
    timeTo: convertTimeToLocal(timeTo),
    compatibility: [],
    devices: [],
  };

  Object.keys(compatibility).forEach(key => {
    if (Object.keys(firmwareCompatibility).indexOf(key) + 1) return;
    form.compatibility.push({ key, value: compatibility[key].map(value => ({ value })) });
  });
  if (devices?.length) {
    devices.forEach(value => form.devices.push({ value }));
  }
  return { form, newFirmwareCompatibility: decodeFirmware(firmwareCompatibility, compatibility) };
};
export const decodeFirmware = (compatibility, taskCompatibility = {}) => {
  const newCompatibility = {};
  Object.keys(compatibility).forEach(key => {
    newCompatibility[key] = {};
    const taskValues = taskCompatibility[key];
    compatibility[key].forEach(value => {
      newCompatibility[key][value] = taskValues ? !!(taskValues.indexOf(value) + 1) : true;
    });
  });
  return newCompatibility;
};
