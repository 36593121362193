import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const USER_FORM = password => [
  [
    {
      key: 'surname',
      title: 'Фамилия',
      options: {
        required: ERRORS.REQUIRED('Фамилия'),
        validate: v => FIELD_VALIDATION.DURATION(v, 2, 50),
      },
      visible: () => true,
    },
    {
      key: 'name',
      title: 'Имя',
      options: {
        required: ERRORS.REQUIRED('Имя'),
        validate: v => FIELD_VALIDATION.DURATION(v, 2, 50),
      },
      visible: () => true,
    },
  ],
  [
    {
      key: 'phone',
      mask: '+79999999999',
      title: 'Телефон',
      options: {
        required: ERRORS.REQUIRED('Телефон'),
        pattern: FIELD_VALIDATION.PHONE,
      },
      visible: () => true,
    },
    {
      key: 'email',
      title: 'E-mail',
      options: {
        required: ERRORS.REQUIRED('E-mail'),
        pattern: FIELD_VALIDATION.EMAIL,
      },
      visible: () => true,
    },
  ],
  [
    {
      key: 'password',
      type: 'password',
      title: 'Пароль',
      options: {
        required: ERRORS.REQUIRED('Пароль'),
        pattern: FIELD_VALIDATION.PASSWORD,
      },
      visible: isCreate => isCreate,
    },
    {
      key: 'repeatPassword',
      type: 'password',
      title: 'Подтверждение пароля',
      options: {
        required: ERRORS.REQUIRED('Подтверждение пароля'),
        validate: v => v === password || ERRORS.PASSWORD_REPEAT,
      },
      visible: isCreate => isCreate,
    },
  ],
];
