import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { userService } from 'services';
import { ROLES, useSuccess } from 'helpers';
import { USER_FORM } from './helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Button, ButtonTypes, Input, updateFormErrors, ERRORS, Select } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './UserPage.module.css';

const UserPage = () => {
  const { userId } = useParams();
  const isCreate = userId === 'new';

  const navigate = useNavigate();
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({ mode: 'onTouched' });
  const password = watch('password');

  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      userService
        .getUser(userId)
        .then(({ status, data }) => {
          if (status === 200) {
            const { name, surname, phone, role, email } = data || {};
            reset({ name, surname, phone, role: role.split(',')[0], email });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isCreate, reset, userId]);

  const submit = form => {
    setLoading(true);
    const method = isCreate ? userService.createUser : userService.updateUser;
    const body = isCreate ? form : { name: form.name, surname: form.surname, phone: form.phone };
    if (isCreate) delete body.repeatPassword;

    method(body, userId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          if (isCreate) navigate(`/users/${data.id}`);
          setSuccess(`${isCreate ? 'Пользователь создан' : 'Изменения сохранены'} `);
        }
      })
      .finally(() => setLoading(false));
  };

  const exit = () => (isDirty ? setExitModal(true) : navigate('/users'));

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.SR} onClick={exit}>
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} пользователя</h3>

        <Button className={styles['submit-btn']} disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>
      </header>

      <div className={styles['fields']}>
        {USER_FORM(password).map(row => (
          <div key={'row-' + row[0].key} className={styles['fields-row']}>
            {row.map(
              ({ key, mask, title, options, type, visible }) =>
                visible(isCreate) && (
                  <Input
                    key={key}
                    type={type}
                    mask={mask}
                    placeholder={title}
                    className={styles['field']}
                    register={register(key, options)}
                    error={errors[key]}
                    disabled={loading || !!(key === 'email' && !isCreate)}
                  />
                ),
            )}
          </div>
        ))}

        <Controller
          control={control}
          name="role"
          rules={{ required: ERRORS.REQUIRED('Роль') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['field']}
              placeholder="Роль"
              list={Object.values(ROLES).map(id => ({ id }))}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={loading || !isCreate}
              titleKey="id"
            />
          )}
        />
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate('/users')} />
    </div>
  );
};

export default UserPage;
