export const encodeForm = form => {
  const { name, status, compatibility, targetVersion } = form;

  const body = { name, status };
  if (compatibility?.length) {
    body.compatibility = {};
    compatibility.forEach(({ key, value }) => (body.compatibility[key] = value.map(v => v.value)));
  }
  if (targetVersion?.length) {
    body.targetVersion = {};
    targetVersion.forEach(({ key, value }) => (body.targetVersion[key] = value));
  }
  return body;
};
export const decodeForm = body => {
  const { _id, name, status, compatibility, targetVersion } = body;

  const form = { _id, name, status };
  if (compatibility) {
    form.compatibility = [];
    Object.keys(compatibility).forEach(key =>
      form.compatibility.push({ key, value: compatibility[key].map(value => ({ value })) }),
    );
  }
  if (targetVersion) {
    form.targetVersion = [];
    Object.keys(targetVersion).forEach(key => form.targetVersion.push({ key, value: targetVersion[key] }));
  }
  return form;
};
