import { createBaseApi, configRequest, prepareQuery } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const taskService = { getTask, getTasks, createTask, updateTask, deleteTask };

function getTask(id) {
  return baseApi.get(`/tasks/${id}`, configRequest);
}

function getTasks(params = {}) {
  return baseApi.get(`/tasks?${prepareQuery(trimObject(params))}`, configRequest);
}

function createTask(body) {
  return baseApi.post('/tasks', trimObject(body), configRequest);
}

function updateTask(body, id) {
  return baseApi.put(`/tasks/${id}`, trimObject(body), configRequest);
}

function deleteTask(id) {
  return baseApi.delete(`/tasks/${id}`, configRequest);
}
