import { createBaseApi, configRequest, prepareQuery } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const deviceService = { getDevicesFailed };

function getDevicesFailed(params = {}) {
  return baseApi.get(`/devices/failed?${prepareQuery(trimObject(params))}`, configRequest);
}
