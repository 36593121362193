import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { userService } from 'services';
import { COLUMNS, FILTERS } from './helpers';

import { Button, ButtonTypes, ListFilters, Popper, Table, pageSize } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ChangePasswordModal } from 'components/Login/ChangePasswordModal/ChangePasswordModal';
import { UserDevices } from './UserDevices/UserDevices';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as ResetPass } from 'icons/lock_reset.svg';
import { ReactComponent as Devices } from 'icons/devices.svg';

import styles from './Users.module.css';

const Users = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [passModal, setPassModal] = useState(false);
  const [devicesModal, setDevicesModal] = useState('');

  const fetchUsers = useCallback(() => {
    setLoading(true);
    userService
      .getUsers({ page, pageSize, ...filters })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page, filters]);

  useEffect(() => {
    fetchUsers();
  }, [page, filters, fetchUsers]);

  const handler = row => ({
    ...row,
    devices: (
      <Popper popup={'Устройства пользователя'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDevicesModal(row._id)}>
          <Devices />
        </Button>
      </Popper>
    ),
    resetPass: (
      <Popper popup={'Смена пароля'}>
        <Button variant={ButtonTypes.TL} onClick={() => setPassModal(row._id)}>
          <ResetPass />
        </Button>
      </Popper>
    ),
    edit: (
      <Popper popup={'Редактирование'}>
        <Button variant={ButtonTypes.TL} onClick={() => navigate(row._id)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удаление'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Пользователи"
        fields={FILTERS}
        filters={filters}
        createHandler={() => navigate('/users/new')}
        setFilters={setFilters}
        updateList={fetchUsers}
      />

      <ChangePasswordModal id={passModal} open={!!passModal} setOpen={() => setPassModal(!passModal)} />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="_id"
        handler={handler}
      />

      <ConfirmModal
        open={deleteModal?._id}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchUsers}
        method={() => userService.deleteUser(deleteModal)}
        header="Удалить пользователя?"
        description="Я понимаю, что восстановить пользователя будет невозможно"
      />

      <UserDevices id={devicesModal} setOpen={() => setDevicesModal('')} />
    </>
  );
};

export default Users;
