import { createBaseApi, configRequest, prepareQuery } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const firmwareService = {
  getFirmware,
  getFirmwares,
  createFirmware,
  updateFirmware,
  deleteFirmware,
  uploadFirmware,
};

function getFirmware(id) {
  return baseApi.get(`/firmwares/${id}`, configRequest);
}

function getFirmwares(params = {}) {
  return baseApi.get(`/firmwares?${prepareQuery(trimObject(params))}`, configRequest);
}

function createFirmware(body) {
  return baseApi.post('/firmwares', trimObject(body), configRequest);
}

function updateFirmware(body, id) {
  return baseApi.put(`/firmwares/${id}`, trimObject(body), configRequest);
}

function deleteFirmware(id) {
  return baseApi.delete(`/firmwares/${id}`, configRequest);
}

function uploadFirmware(id, form) {
  const config = { ...configRequest };
  const headers = { ...config.headers };
  headers['Content-Type'] = 'multipart/form-data';
  // headers['X-Requested-With'] = 'XMLHttpRequest';
  config.headers = headers;

  return baseApi.post(`/firmwares/${id}/file`, form, config);
}
