import { createBaseApi, configRequest } from './baseApi';

const baseApi = createBaseApi();
export const apiKeysService = { getApiKeys, createApiKey, updateApiKey, getApiKey, deleteApiKey };

function getApiKey(id) {
  return baseApi.get(`/apikeys/${id}`, configRequest);
}

function getApiKeys() {
  return baseApi.get(`/apikeys`, configRequest);
}

function createApiKey(body) {
  return baseApi.post(`/apikeys`, body, configRequest);
}

function updateApiKey(body, id) {
  return baseApi.put(`/apikeys/${id}`, body, configRequest);
}

function deleteApiKey(id) {
  return baseApi.delete(`/apikeys/${id}`, configRequest);
}
