export const FILTERS = [{ key: 'esn', title: 'ESN' }];

export const COLUMNS = [
  { key: 'esn', title: 'ESN' },
  { key: 'failedAttemptCount', title: 'Неудачных попыток' },
  { key: 'lastFailed', title: 'Последняя попытка' },
  { key: 'firmwareName', title: 'Прошивка' },
  { key: 'taskName', title: 'Задание' },
  { key: 'failReason', title: 'Причина фейла' },
  { key: 'history', title: '' },
];
