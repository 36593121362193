import { ERRORS } from '@bs/techconnect-ui';

export const SETTINGS_TABS = { g: 'globalSettingsTab', a: 'apiKeyTab' };
export const SETTINGS_EDIT = [
  {
    key: 'timeout',
    title: 'Таймаут на выполнение команд, секунд',
    options: {
      required: ERRORS.REQUIRED('Таймаут'),
      validate: v => (v >= 300 && v <= 5400) || 'Значение должно быть в диапазоне от 300 до 5400 секунд',
    },
  },
  {
    key: 'queueCapacity',
    title: 'Допустимый объем очереди команд',
    options: {
      required: ERRORS.REQUIRED('Допустимый объем очереди команд'),
      validate: v => (v >= 50 && v <= 1000) || 'Значение должно быть в диапазоне от 50 до 1000',
    },
  },
];

export const COLUMNS = [
  { key: 'api-key', title: 'api-key' },
  { key: 'description', title: 'Описание' },
  { key: 'expiresAt', title: 'Дата истечения срока действия' },
  { key: 'isBlocked', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];
