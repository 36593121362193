import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NAV_MENU, useUser } from 'helpers';

import { ChangePasswordModal } from 'components/Login/ChangePasswordModal/ChangePasswordModal';
import { Button, ButtonTypes } from '@bs/techconnect-ui';

import styles from './Navigate.module.css';

const Navigate = () => {
  const {
    user: { role },
  } = useUser();
  const [open, setOpen] = useState(false);

  const abilityFilter = ({ ability = [] }) => ability.indexOf(role) + 1;

  return (
    <nav className={styles['wrapper']}>
      {NAV_MENU.filter(abilityFilter).map(({ key, title }) => (
        <NavLink key={key} to={key} className={({ isActive }) => (isActive ? styles['item__active'] : styles['item'])}>
          {title}
        </NavLink>
      ))}

      <Button className={styles['change-pass']} variant={ButtonTypes.T} onClick={() => setOpen(!open)}>
        Сменить пароль
        <ChangePasswordModal open={open} setOpen={() => setOpen(!open)} />
      </Button>
      <div className={styles['copyright']}>Ⓒ Technolabs</div>
    </nav>
  );
};

export default Navigate;
