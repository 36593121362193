import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  ButtonTypes,
  Copy,
  Input,
  ListFilters,
  Popper,
  Shorter,
  Table,
  parseDate,
  shortString,
} from '@bs/techconnect-ui';
import { COLUMNS, SETTINGS_EDIT, SETTINGS_TABS } from './helpers';
import { apiKeysService, settingsService } from 'services';
import { useSuccess } from 'helpers';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './Settings.module.css';

const Settings = () => {
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();

  const [tab, setTab] = useState(SETTINGS_TABS.g);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const isGlobal = tab === SETTINGS_TABS.g;
  const isApiKey = tab === SETTINGS_TABS.a;

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  useEffect(() => {
    setLoading(true);
    if (isGlobal) {
      settingsService
        .getSettings()
        .then(({ status, data }) => {
          if (status === 200 && data) {
            reset(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isGlobal, reset]);

  const fetchApiKeys = useCallback(() => {
    setLoading(true);
    apiKeysService
      .getApiKeys()
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data?.items);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (isApiKey) fetchApiKeys();
  }, [fetchApiKeys, isApiKey]);

  const submit = form => {
    setLoading(true);
    settingsService
      .updateSettings(form)
      .then(({ status }) => {
        if (status === 200) {
          setSuccess('Настройки сохранены');
        }
      })
      .finally(() => setLoading(false));
  };

  const action_items = [
    isGlobal && {
      key: 'saveSettings',
      element: (
        <Button className={styles['submit-btn']} onClick={() => handleSubmit(submit)()}>
          Сохранить
        </Button>
      ),
    },
  ];

  const tabs = () => (
    <ButtonGroup
      buttons={[
        { key: SETTINGS_TABS.g, title: 'Глобальные настройки' },
        { key: SETTINGS_TABS.a, title: 'API KEYs' },
      ]}
      selected={[tab]}
      onSelect={setTab}
      className={styles['toggle-btn']}
      disabled={loading}
    />
  );

  const handler = row => ({
    ...row,
    'api-key': (
      <Copy value={row['api-key']} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row['api-key'], 20)}
      </Copy>
    ),
    description: <Shorter length={400}>{row?.description}</Shorter>,
    isBlocked: row?.isBlocked && 'Заблокирован',
    expiresAt: parseDate(row?.expiresAt, 'dd.MM.yy'),
    edit: (
      <Popper popup={'Редактирование'}>
        <Button variant={ButtonTypes.TL} onClick={() => navigate(`/settings/${row._id}`)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удаление'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row._id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });
  return (
    <>
      <ListFilters
        title="Настройки"
        className={styles['header']}
        actions={action_items}
        createHandler={isApiKey && (() => navigate('/settings/new'))}
        Tabs={tabs}
      />
      {isGlobal && (
        <div className={styles['input-settings']}>
          {SETTINGS_EDIT.map(({ key, title, options }) => (
            <Input
              key={key}
              label={title}
              type="number"
              placeholder=" "
              register={register(key, options)}
              error={errors[key]}
            />
          ))}
        </div>
      )}
      {isApiKey && (
        <>
          <Table className={styles['table']} columns={COLUMNS} rows={rows} handler={handler} idKey={rows._id} />
          <ConfirmModal
            open={deleteModal}
            setOpen={() => setDeleteModal(!deleteModal)}
            updateList={fetchApiKeys}
            method={() => apiKeysService.deleteApiKey(deleteModal)}
            header="Удалить API KEY?"
            description="Я понимаю, что восстановить API KEY будет невозможно"
          />
        </>
      )}
    </>
  );
};

export default Settings;
