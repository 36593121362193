import { IndicatorTypes } from '@bs/techconnect-ui';

export const fileNamePlaceholder = 'Выберете файл прошивки';
export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'email', title: 'Пользователь' },
  { key: 'createdAt', title: 'Создание' },
  { key: 'status', title: 'Статус' },
  { key: 'command_history', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const FIRMWARE_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};
export const STATUSES_TITLE = {
  [FIRMWARE_STATUSES.active]: 'Active',
  [FIRMWARE_STATUSES.inactive]: 'Inactive',
};
export const FIRMWARE_INDICATOR = {
  [FIRMWARE_STATUSES.active]: IndicatorTypes.success,
  [FIRMWARE_STATUSES.inactive]: IndicatorTypes.none,
};
