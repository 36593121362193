import axios from 'axios';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise => {
    if (error) promise.reject(error);
    else promise.resolve(token);
  });

  failedQueue = [];
};

export function createBaseApi(apiVersion = 'v1', timeout = 0) {
  const baseApi = axios.create({ baseURL: `/api/${apiVersion}` });

  // Обработка ОТВЕТА
  baseApi.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { refreshToken } = JSON.parse(localStorage.getItem('user')) || {};
      const { config: originalRequest, response } = error;
      const { status } = response;

      if (status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = ['Bearer', token].join(' ');
              return axios(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          return axios
            .post(`/api/${apiVersion}/auth/tokenRefresh`, { refreshToken }, configRequest)
            .then(response => {
              if (response.status !== 200) {
                localStorage.removeItem('user');
                window.dispatchEvent(new Event('storage'));
              }

              const { accessToken, refreshToken } = response.data;
              localStorage.setItem('user', JSON.stringify({ accessToken, refreshToken }));
              window.dispatchEvent(new Event('storage'));
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
              originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

              resolve(axios(originalRequest));
              processQueue(null, accessToken);
              resolve(error.config);
            })
            .catch(e => {
              reject(e);
              processQueue(e, null);

              localStorage.removeItem('user');
              window.location.pathname = '/login';
            })
            .finally(() => (isRefreshing = false));
        });
      }

      //Проброс ошибки для глобального стора
      sessionStorage.setItem('error', JSON.stringify(response));
      //На других вкладках это будет вызывать два вызова события "storage"
      //Проблемой быть не должно
      window.dispatchEvent(new Event('storage'));

      return response;
    },
  );

  // Обработка ЗАПРОСА
  baseApi.interceptors.request.use(
    config => {
      const { accessToken } = JSON.parse(localStorage.getItem('user')) || {};
      config.headers = {
        ...config.headers,
        Authorization: ['Bearer', accessToken].join(' '),
      };
      config.timeout = timeout;
      return config;
    },
    error => {
      console.log(error);
      return Promise.reject(error);
    },
  );

  return baseApi;
}

export const configRequest = createConfig();

function createConfig() {
  return {
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  };
}

export const prepareQuery = params => {
  const query = [];
  Object.keys(params).forEach(key => {
    if (!params[key]) return;
    const value = Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key];
    query.push([key, encodeURIComponent(value)].join('='));
  });
  return query.join('&');
};
