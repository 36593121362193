import { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { PASSWORD_FORM } from './helpers';
import { authService } from 'services';
import { Button, ButtonTypes, Input, Modal, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Eye } from 'icons/eye.svg';
import { ReactComponent as EyeOff } from 'icons/eye_off.svg';

import styles from './ChangePasswordModal.module.css';

export const ChangePasswordModal = ({ id, open, setOpen }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    watch,
    reset,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });
  const password = watch('password');

  const close = () => {
    reset({});
    setOpen();
  };

  const change = form => {
    const { password } = form;
    const method = id ? authService.changePassword : authService.changeSelfPassword;
    method({ password }, id).then(({ data, status }) => {
      if (status !== 204) updateFormErrors(data, form, setError);
      if (status === 204) close();
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal className={styles['create-wrapper']} open={open}>
      <header>Смена пароля</header>
      <Button variant={ButtonTypes.T} className={styles['create-close']} onClick={close}>
        <Close />
      </Button>
      {PASSWORD_FORM(password).map(({ key, title, options, type }) => (
        <div key={key}>
          <button type="button" className={styles['toggleEye']} onClick={togglePasswordVisibility}>
            {showPassword ? <EyeOff /> : <Eye />}
          </button>
          <Input
            type={showPassword ? 'text' : type}
            placeholder={title}
            className={styles['params-field']}
            register={register(key, options)}
            error={errors[key]}
            disabled={!!(key === 'email')}
          />
        </div>
      ))}

      <Button
        className={styles['submit']}
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={() => handleSubmit(change)()}
      >
        Сменить пароль
      </Button>
    </Modal>
  );
};

ChangePasswordModal.defaultProps = {
  id: false,
  open: false,
  setOpen: () => {},
};

ChangePasswordModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
