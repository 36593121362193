import { createBaseApi, configRequest } from './baseApi';
import { trimObject } from '@bs/techconnect-ui';

const baseApi = createBaseApi();

export const authService = { signIn, changeSelfPassword, changePassword };

function signIn(authData) {
  return baseApi.post('/auth/login', trimObject(authData), configRequest);
}

function changeSelfPassword(body) {
  return baseApi.post('/users/self/password', trimObject(body), configRequest);
}

function changePassword(body, userId) {
  return baseApi.post(`/users/${userId}/password`, trimObject(body), configRequest);
}
